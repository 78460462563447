import React, {Component, Fragment} from 'react';
import { Helmet } from 'react-helmet';
import logo from '../images/webiya-white-logo.svg';
import '../style/index.scss';

console.log(`      %c*(((((((((((((((((((((((((((((((((((((*
  (((((((((((((((((((((((((((((((((((((((((((((((
 (((((((((((((((((((((((((((((((((((((((((((((((((
((((((((((((((((((((((((((((((((((((((((((((((((((
((((((((((((((((((((((((((((((((((((((((((((((((((
((((((((((((((((((((((((((((((((((((((((((((((((((
((((((((((((((((((((((((((((((((((((((((((((((((((
((((((((/    (((((   /((((     (((((/    (((((((((
(((((((((     (((.    ((((    ((((((     /((((((((
((((((((((    (((      ((    (((((((((((((((((((((
(((((((((((    (   (   (,   .(((((((((((/(((((((((
((((((((((((      .((       ((((((((     (((((((((
((((((((((((*     (((,     ((((((((,     (((((((((
(((((((((((((,   (((((    (((((((((     ((((((((((
((((((((((((((((((((((((((((((((((*   /(((((((((((
((((((((((((((((((((((((((((((((((((((((((((((((((
((((((((((((((((((((((((((((((((((((((((((((((((((
((((((((((((((((((((((((((((((((((((((((((((((((((
 (((((((((((((((((((((((((((((((((((((((((((((((((
  ((((((((((((((((((((((((((((((((((((((((((((((*
`, "color: #F52944", "fffff"
) 
class IndexPage extends Component { 
  render() {
        return (
            <Fragment>
                <Helmet>
                  <title>Webiya</title>
                  <meta name="description" content="We're Webiya, an expert Frontend & Full Stack consulting and services agency based in Tel Aviv, Israel."/>
                  <link rel="canonical" href="https://webiya.co.il"/>
                </Helmet>
                <section className="page-container">
                  <h1 className="title">
                    <img src={logo} className="logo" title="Webiya" alt="Webiya ווביה"/>
                  </h1>
                  <h2 className="subtitle">
                    Frontend & Full Stack Expert Crafters
                  </h2>
                  <ul className="contact">
                    <li className="contact-item">
                      <a className="contact-content" href="mailto:info@webiya.co.il">info@webiya.co.il</a>
                    </li>
                    <li className="contact-item">
                      <a className="contact-content" href="tel:+972546490123">+972-(0)54-6490123</a>
                    </li>
                  </ul>
                </section>
            </Fragment>
        );
    }

}

export default IndexPage;
